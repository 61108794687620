<template>
  <button 
    @click="approveProject()" 
    v-if="this.project.approve == 0"
    type="button" 
    class="btn btn-approve"
  >
    <i class="fa fa-check"></i> 
    เริ่มเสนอราคา
  </button>
</template>


<script>
import {apiRequest} from '@/utils/axios/axiosInstance.js';

export default {
  props: {
    project: Object
  },

  data() {
    return {
      projectId: ''
    };
  },
  created() {
    this.projectId = this.$route.params.id;
  },
  methods: {
    approveProject() {
      let conf = confirm('คุณต้องการอนุมัติโครงการนี้ใช่หรือไม่?');

      if(conf) {
        this.$store.commit('setLoaderShow', true);

        apiRequest
          .post(
            '/project/approve', 
            {
              project_id: this.projectId
            }
          )
          .then((res) => {
            this.$store.commit('setLoaderShow', false);
            
            // if approve project status is true, refresh this page
            // if not alert message
            if (res.data.status==200) {
              this.$router.go();
            } else {
              alert('การบันทึกข้อมูลผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
          });
      }
    },
  }
}
</script>