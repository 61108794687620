<template>
  <div
    class="project--block"
  >
    <SmallTitle
      title="ข้อมูลระบบ"
      class="bottom-line"
      :editLink="routeEdit"
    />
    <div class="project--code">
      <span class="code">
        {{ project.code }}
      </span>
      (<a :href="previewLink" target="_blank">preview</a>)
    </div>

    <ProjectInfoBlock title="สถานะโครงการ">
      <ProjectStatusLabel :item="project" />
    </ProjectInfoBlock>

    <ProjectInfoBlock title="จำนวนผู้เสนอราคาที่เปิดรับ">
      {{ project.auction_limit }} ราย
    </ProjectInfoBlock>

    <ProjectInfoBlock 
      title="วันสิ้นสุดการประมูลโครงการ"
      v-if="isApproved()"
    >
      {{ project.auction_end }}
    </ProjectInfoBlock>
  </div>
</template>

<script>
// Load library and variables
import {globalVars} from '@/variables/config.js';
// import {dateInputToFormat} from '@/utils/date/dateInputToFormat.js';

// Load components
import SmallTitle         from '@/components/common/SmallTitle.vue';
import ProjectInfoBlock   from '@/components/project/view/block/ProjectInfoBlock.vue';
import ProjectStatusLabel from '@/components/project/ProjectStatusLabel.vue'

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  props: {
    project: Object
  },

  /**
   * All data
   */
  data() {
    return {
      projectId: '',
      previewLink: '',
      routeEdit:  '',
    }
  },

  /**
   * Registration components
   */
  components: {
    SmallTitle,
    ProjectInfoBlock,
    ProjectStatusLabel,
  },

  /**
   * Initial this component when created
   */
  created() {
    this.projectId = this.$route.params.id;
    this.previewLink = globalVars.url.member + 'project/view/' + this.projectId;
    this.routeEdit = '/project/edit/code/' + this.projectId;

    // prepare data
    if(this.isApproved()) {
      // this.project.auction_end = dateInputToFormat(this.project.auction_end);
    }
  },

  /**
   * Method
   */
  methods: {
    /**
     * Detect current project is approve or not
     */
    isApproved() {
      return this.project.approve == 9;
    }
  }
}
</script>


<style scoped>
.project--code .code {
  font: 500 20px/30px Roboto;
  color: #234858;
}
</style>