<template>
  <div>
    <div 
      v-if=" costRemain == 0 "
      class="btn-set"
    >
      <button 
        class="print-doc"
        @click="createDoc"
      >
        <i class="fa fa-file-text-o"></i>
        <span v-if="isConstructor()">
          สร้างเอกสารการจ้างงาน
        </span>
        <span v-else>
          สร้างเอกสารสรุปงวดชำระเงิน
        </span>
      </button>
    </div>

    <div class="description">
      <p>
        <!-- 
          Display calcurate mode.
          'personal' or 'company'
        -->
        <strong v-if="hasVat()">
          (นิติบุคคล) - มี vat และหัก ณ ที่จ่าย
        </strong>
        <strong v-else>
          (บุคคลธรรมดา) - คำนวนแบบไม่มี vat และหัก ณ ที่จ่าย
        </strong>

        <!-- 
          Display remain cost.
        -->
        <span 
          v-if=" !isSigned() "
        >
          <br>
          ยังเหลือยอดเงินที่ยังไม่ได้ทำรายการ 
          <span class="remain-budget">{{ costRemain | numFormat }} </span>
          บาท 
        </span>

        <!-- 
          Display real cost.
        -->
        จากยอด
        <span class="remain-budget">
          {{ cost | numFormat }}
        </span>
        บาท
      </p>
    </div>
    

    <!-- 
      Button for add new PaymentPeriod item
    -->
    <div 
      class="btn-set"
      v-if="costRemain > 0"
    >
      <a 
        href="#"
        @click="addPaymentRow"
        class="add"
      >
        <i class="fa fa-plus"></i>
        เพิ่มรายการ
      </a>
    </div>

    
    <!--
      PaymentPeriod output.
      display by HTML table
        - if 'agreement' status is 'waiting_sign', show edit and remove button
        - if 'agreement' status is 'signed', hide edit/remove button and show AddPaymentButton
    -->
    <section class="data-list">
      <table class="tb-data tb--payment">
        <colgroup>
          <col>
          <col style="width: 120px">
          <col style="width: 100px">
          <col style="width: 120px">
          <col v-if="isConstructor()" style="width: 120px">
          <col style="width: 120px">
        </colgroup>
        <thead>
          <tr>
            <!-- <th></th> -->
            <th>งวดที่</th>
            <th>มูลค่า (inc.Vat)</th>
            <th>หัก ณ ที่จ่าย</th>
            <th>มูลค่า (ex.Vat)</th>
            <th v-if="isConstructor()">Fee (inc.Vat)</th>
            <th>ยอดจ่ายจริง</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(item, key) in data" 
            :key="key"
            class="tb--payment--item"
          >
            <!-- <td></td> -->
            <td>
              <h5 class="tb--payment--title">
                งวดที่ {{ key + 1 }}
              </h5>
              <div v-if="isEditRow(item)">
                <textarea v-model="item.detail"></textarea>
                <button @click="saveRowBtn(item)">บันทึก</button>
              </div>
              <div v-else>
                <div class="tb--payment--detail">
                  <nl2br :text="item.detail" />
                </div>
                <ul 
                  v-if=" !isSigned() "
                  class="tb--payment--btn"
                >
                  <li><a href="#" @click.prevent="editRowBtn(item)">แก้ไข</a></li>
                  <li><a href="#" @click.prevent="removeRowBtn(key, item)" class="delete">ลบ</a></li>
                </ul>

                <!-- Proof history in this period item -->
                <div 
                  v-if=" isSigned() "
                >
                  <PeriodProofConstructor 
                    v-if=" isConstructor() "
                  />
                  <PeriodProofOwner 
                    v-else
                  />
                </div>
              </div>
            </td>
            <td>
              <div v-if="isEditRow(item)">
                <input 
                  v-model="item.cost"
                  @input="calRowData(item)"
                  @keypress="isNumber($event)"
                >
              </div>
              <div v-else>
                {{ item.cost | numFormat }}
              </div>
            </td>
            <td>{{ item.vat | numFormat }}</td>
            <td>{{ item.withholding | numFormat }}</td>
            <td 
              v-if="isConstructor()"
            >
              {{ item.fee_vat | numFormat }}
              <span class="fee-ex-vat">
                ({{ item.fee | numFormat }})
              </span>
            </td>
            <td>{{ item.total | numFormat }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <!-- <th></th> -->
            <th>งวดที่</th>
            <th>มูลค่า (inc.Vat)</th>
            <th>หัก ณ ที่จ่าย</th>
            <th>มูลค่า (ex.Vat)</th>
            <th v-if="isConstructor()">Fee (inc.Vat)</th>
            <th>ยอดจ่ายจริง</th>
          </tr>
        </tfoot>
      </table>
    </section>
  </div>
</template>

<script>
// load library and variables
import {floatComma} from '@/utils/number/floatComma.js';
import {apiRequest} from '@/utils/axios/axiosInstance.js';

// load component
import nl2br                  from '@/components/text/nl2br.vue'
import PeriodProofOwner       from '@/components/project/payment/history/PeriodProofOwner.vue'
import PeriodProofConstructor from '@/components/project/payment/history/PeriodProofConstructor.vue'


export default {
  /**
   * @param {mode} - 'owner' or 'constructor'
   * @param {project} -
   */
  props: {
    mode: {
      type: String,
      default: 'owner'
    },
    project: Object,
  },

  /**
   * Register component
   */
  components: {
    nl2br,
    PeriodProofOwner,
    PeriodProofConstructor,
  },

  /**
   * Components data
   * @var {member}      - member value for this list
   * @var {buzType}     - member bussiness type value for use
   * @var {cost}        - real cost
   * @var {costRemain}  - remain cost from real cost decrease by cost in list
   * @var {data}        - list data
   * @var {dataTmp}     - default item data
   */
  data() {
    return {
      member:     {},
      buzType:    0,
      cost:       0,
      costRemain: 0,
      data:       [],
      dataTmp: {
        detail:      '',
        cost:        0,
        vat:         0,
        withholding: 0,
        fee:         0,
        fee_vat:     0,
        total:       0,
        isEdit:      true
      },
    }
  },

  /**
   * Created
   */
  created() {
    // Project real cost for calcurate. 
    this.cost = this.project.budget_real;

    // member data for list
    this.member = (this.mode == 'owner') ? this.project.owner_data : this.project.winner_data;
    this.buzType = this.member.business_type;

    // prepare list data and detect is empty or not
    let listData = (this.mode == 'owner') ? this.project.owner_payment : this.project.const_payment ;
    if (listData !== false) {
      this.data = listData;
    }

    // calculate remain cost
    this.countRemainCost();

    console.log(this.project);
  },

  /**
   * Filters
   */
  filters: {
    /**
     * Filter number formatting with 2 decimal
     */
    numFormat: function (value) {
      return floatComma(value);
    },

    /**
     * 
     */
    nl2br:function (str) {
      if (typeof str === 'undefined' || str === null) return '';
      
      var breakTag = `<br />`;
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
  },
  
  /**
   * Methods
   */
  methods: {
    /**
     * Detect list type is Constructor or not
     */
    isConstructor() {
      return this.mode === 'constructor';
    },

    /**
     * check status for calculate with vat or not
     */
    hasVat() {
      return this.buzType == 9;
    },

    /**
     * Detect agreement status is 'signed'
     */
    isSigned() {
      return this.project.agreement == 9;
    },

    /**
     * detect key is number only
     */
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    /**
     * Check row status is show edit or not
     */
    isEditRow(item) {
      return item.isEdit == true;
    },

    /**
     * Handle edit button click
     *    change design to edit form in row
     */
    editRowBtn(item) {
      item.isEdit = true;
    },

    /**
     * Handle edit button click
     *    change design to edit form in row
     */
    removeRowBtn(index) { //, item
      let conf = confirm('คุณต้องการจะลบรายการงวดที่ '+index+' ใช่หรือไม่ ?');

      if (conf) {
        this.data.splice(index,1);
        this.countRemainCost();
        this.saveList();
      }
    },

    /**
     * Handle for click save row button
     */
    saveRowBtn(item) {
      // cal remain cost
      this.countRemainCost();

      if(this.costRemain < 0) {
        alert('คุณกรอกตัวเลขเกินงบก่อสร้างจริง กรุณาปรับแก้และลองใหม่อีกครั้ง !!!');
        item.cost = 0;
      } else {
        // save data
        this.saveList();

        // switch display back
        item.isEdit = false;
      }
    },

    /**
     * Calculate row data when key cost
     */
    calRowData(item) {
      let cost = Number(item.cost);
      let cost_ex_vat = 0;

      if (this.buzType==9) {
        item.vat = ((cost/107) * 7).toFixed(2);
  
        cost_ex_vat = cost - item.vat;
        item.withholding = (cost_ex_vat * 0.03).toFixed(2);
      } else {
        item.vat = 0;
        item.withholding = 0;
        cost_ex_vat = cost - item.vat;
      }
      item.fee = cost * 0.1;
      item.fee_vat = item.fee * 1.07;
      item.total = (cost - item.withholding).toFixed(2);
    },

    /**
     * Add new payment row
     */
    addPaymentRow() {
      this.data.push(
        JSON.parse(JSON.stringify(this.dataTmp))
      );
    },

    /**
     * count remain cost
     */
    countRemainCost() {
      let costRemain = this.cost;
      if(this.data.length > 0) {
        this.data.forEach((item) => costRemain -= item.cost);
      }
      this.costRemain = costRemain;
    },

    /**
     * Save list to DB
     */
    /**
     * Save value to database via API
     */
    saveList() {
      // show main loader
      this.$store.commit('setLoaderShow', true);
      
      let form = {
        project_id: this.project.PID,
      };
      if (this.mode == 'owner') {
        form.owner_payment = this.data
      } else {
        form.const_payment = this.data
      }

      apiRequest
        .patch('/project/', form)
        .then( (res) => {
          if (res.data.status == 200) {
            this.$store.commit('setLoaderShow', false);
          } else {
            // console.log(res.data);
            alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณารีเฟรชหน้านี้และลองใหม่อีกครั้ง !!!');
          }
        });
    },

    /**
     * Create doc click handle
     */
    createDoc() {
      alert('ระบบยังไม่พร้อมใช้งาน');
    }
  }
}
</script>


<style scoped>
.description {
  margin-bottom: 5px;
  color: #555;
}
.description strong {
  font-weight: 500;
  color: #000;
}
.remain-budget {
  font-weight: 500;
  color: #CC0000;
}

.btn-set {
  margin: 5px 0;
}
.btn-set .add {
  color: #37A21D;
}
.btn-set .print-doc {
  background: #37A21D;
}


/**
* table style
*/
.tata-list {
  margin-top: 10px;
}
.tb--payment--title {
  font-size: 15px;
  font-weight: 500;
}
.tb--payment--detail {
  color: #333;
  /* white-space: pre; */
}
.fee-ex-vat {
  display: block;
  color: #888;
}

.tb--payment--item textarea,
.tb--payment--item input {
  width: 100%;
}
.tb--payment--item button {
  padding: 3px 8px;
  border-radius: 4px;
  background: #37A21D;
  color: #fff;
}
.tb--payment--btn {
  margin-top: 10px;
}
.tb--payment--btn li,
.tb--payment--btn a {
  display: inline-block;
  line-height: 18px;
}
.tb--payment--btn li {
  padding-right: 8px;
}
.tb--payment--btn li + li {
  border-left: solid 1px #ccc;
  padding-left: 8px;
}
.tb--payment--btn a {
  color: #37A21D;
}
.tb--payment--btn a.delete {
  color: #c00;
}
</style>