<template>
  <span>
    <span v-for="(line, key) in text.split('\n')" :key="key">
      <br v-if="key != 0">
      {{ line }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>