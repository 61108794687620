<template>
  <div
    class="project--block"
  >
    <SmallTitle
      title="ข้อมูลโครงการ"
      class="bottom-line"
      :editLink="routeEdit"
    />
    
    <ProjectInfoBlock title="ชื่อโครงการ">
      {{ project.name}}
    </ProjectInfoBlock>

    <ProjectInfoBlock title="เจ้าของโครงการ">
      {{ project.owner_data.full_name}}
    </ProjectInfoBlock>

    <ProjectInfoBlock title="ประเภทงาน">
      {{ project.cat | projectCatIdToName }}
    </ProjectInfoBlock>
    
    <ProjectInfoBlock 
      title="ประเภทสิ่งก่อสร้าง"
    >
      {{ project.building_type | projectBuildingTypeToName }}

      <span
        v-if="project.building_type == 90"
      >
        ({{ project.building_detail }})
      </span>
    </ProjectInfoBlock>

    <ProjectInfoBlock 
      title="รายละเอียด"
    >
      {{ project.detail | nullTo('-') }}
    </ProjectInfoBlock>
  </div>
</template>

<script>
// Load library and variables
import {globalVars} from '@/variables/config.js';
// import {dateInputToFormat} from '@/utils/date/dateInputToFormat.js';

// Load components
import SmallTitle         from '@/components/common/SmallTitle.vue';
import ProjectInfoBlock   from '@/components/project/view/block/ProjectInfoBlock.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  props: {
    project: Object
  },

  /**
   * All data
   */
  data() {
    return {
      projectId: '',
      previewLink: '',
      routeEdit:  '',
    }
  },

  /**
   * Registration components
   */
  components: {
    SmallTitle,
    ProjectInfoBlock,
  },

  /**
   * Initial this component when created
   */
  created() {
    this.projectId = this.$route.params.id;
    this.previewLink = globalVars.url.member + 'project/view/' + this.projectId;
    this.routeEdit = '/project/edit/general/' + this.projectId;
  },

  /**
   * Method
   */
  methods: {
    /**
     * Detect current project is approve or not
     */
    isApproved() {
      return this.project.approve == 9;
    }
  }
}
</script>