<template>
  <div>
      <div v-if="isLoaded()">
        <!-- <NotiBar 
          v-if="noti.show" 
          :type="noti.type" 
          :msg="noti.text"
        /> -->

        <!--
          Project header
        -->
        <ProjectViewTitle :title="project.name" />
        <nav 
          v-if="
            project.complete == 9
          "
          class="project-nav"
        >
          <ul>
            <li><a href="#" @click.prevent="setPanel('info')" :class="{ active: tabs.current=='info' }">ข้อมูลโครงการ</a></li>
            <li><a href="#" @click.prevent="setPanel('payment')" :class="{ active: tabs.current=='payment' }">สัญญาหลัก</a></li>
            <!-- <li><a href="#" @click.prevent="setPanel('agreement')" :class="{ active: tabs.current=='agreement' }">เอกสารสัญญา</a></li> -->
            <li>
              <a 
                href="#" 
                @click.prevent="setPanel('increase')" 
                :class="{ active: tabs.current=='increase' }"
                v-if=" project.agreement == 9 "
              >
                งานเพิ่ม/ลด
              </a>
              <span
                v-else
              >
                งานเพิ่ม/ลด
              </span>
            </li>
            <!-- <li>
              <a 
                href="#" 
                @click.prevent="setPanel('decrease')" 
                :class="{ active: tabs.current=='decrease' }"
                v-if=" project.agreement == 9 "
              >
                โครงการลด
              </a>
              <span
                v-else
              >
                โครงการลด
              </span>
            </li> -->
          </ul>
        </nav>
        <ProjectViewTopButton :project="project" />


        <!--
          All panel
        -->
        <ProjectViewInfo 
          :project="project" 
          class="panel"
          :class="{ active: tabs.current=='info' }"
        />
        <ProjectPayment
          :project="project" 
          class="panel"
          :class="{ active: tabs.current=='payment' }"
        />
        <!-- <ProjectAgreement
          :project="project" 
          class="panel"
          :class="{ active: tabs.current=='agreement' }"
        /> -->
        <ProjectIncrease
          :project="project" 
          class="panel"
          :class="{ active: tabs.current=='increase' }"
        />
        <!-- <ProjectDecrease
          :project="project" 
          class="panel"
          :class="{ active: tabs.current=='decrease' }"
        /> -->
      </div>
  </div>
</template>

<script>
/**
 * Project View
 *    Project seporate view with many step 
 *     - 'new' or 'auctioning' : 
 *          User can view info, winner info, manage auctioneer and interester
 *     - 'approve_winner' : 
 *          Same 'auctioning' step' but system will show tab menu for manage 'imcome', 'payment' and 'agreement'.
 *     - 'signed_agreement' : 
 *          Change view of 'imcome', 'payment' and 'agreement' from management function to view only (can add payment history)
 *          User can add Increase/Decrease Project Function.
 *     - 'completed'
 *          User can't edit any data (view only). 
 */

// load library and variables
import {apiRequest} from '@/utils/axios/axiosInstance.js'; //, kwanjaiApiRequest

// Load components
import ProjectViewTopButton from '@/components/project/view/button/ProjectViewTopButton.vue'
import ProjectViewTitle     from '@/components/project/view/block/ProjectViewTitle.vue';
import ProjectViewInfo      from '@/components/project/view/ProjectViewInfo.vue';
import ProjectPayment       from '@/components/project/view/ProjectPayment.vue';
// import ProjectAgreement     from '@/components/project/view/ProjectAgreement.vue';
import ProjectIncrease      from '@/components/project/increase/ProjectIncrease.vue';
// import ProjectDecrease      from '@/components/project/decrease/ProjectDecrease.vue';

// Load CSS
import '@/assets/css/project/project-form.css';


export default {
  /**
   * Data
   */
  data() {
    return {
      noti: {
        show: false,
        type: 'success',
        text: '',
      },
      tabs: {
        current: 'info'
      },
      project: {}
    }
  },

  /**
   * Registration components
   */
  components: {
    ProjectViewTitle,
    ProjectViewTopButton,
    ProjectViewInfo,
    ProjectPayment,
    // ProjectAgreement,
    ProjectIncrease,
    // ProjectDecrease,
  },

  /**
   * Initial this component when created
   */
  created() {
    this.projectId = this.$route.params.id;
    
    if(this.$route.hash !== '') {
      this.tabs.current = this.$route.hash.substr(1)
    }

    // set page title
    this.$store.commit('setPageTitle', 'Project Detail');
    this.$store.commit('setHeadTitle', 'Project Detail');

    // hide main loader
    this.$store.commit('setLoaderShow', false);

    // get project data
    this.getData();
  },

  /**
   * Methods
   */
  methods: {
    /**
     * Detect page loaded
     */
    isLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * Get project data from API request
     */
    getData:function() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get('/project/' + this.projectId)
        .then( (res) => {
          if (res.data.status != 200) {
            alert('การโหลดข้อมูลมีปัญหา กรุณาลองรีเฟรชหน้านี้ใหม่อีกครั้ง หรือกด Back เพื่อกลับไปหน้าเดิมของท่าน !!!')
          }else{
            this.project = res.data.result;
            this.$store.commit('setHeadTitle', 'Project View #' + this.project.code);
            this.$store.commit('setLoaderShow', false);
          }
        });
    },

    /**
     * setPanel
     *    change data by click nav
     */
    setPanel(name) {
      this.tabs.current = name;
    },
  }
}
</script>


<style scoped>
.panel {
  display: none;
}
.panel.active {
  display: block;
}
</style>