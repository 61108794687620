<template>
  <div class="duck-form">
    <fieldset class="form-block">
      <div class="form-container">
        <SmallTitle 
          title="ข้อมูลการทำสัญญา"
          class="bottom-line"
          :editLink="editRoutePath"
        />
        <ProjectInfoBlock title="วันที่ทำสัญญา">
          {{ project.sign_date }}
        </ProjectInfoBlock>

        <ProjectInfoBlock title="เอกสารจาก Shared Drive">
          <a 
            v-if="project.agreement_drive != ''" 
            :href="project.agreement_drive"
            target="_blank"
          >
            {{ project.agreement_drive }}
          </a>
          <span v-else>
            -
          </span>
        </ProjectInfoBlock>

        <ProjectInfoBlock title="เอกสารที่ upload ภายในระบบ Beaverman">
          <MultipleFileUploader 
            :parentId="projectId" 
            :showUploader="false" 
            :editLink="editRoutePath"
            section="project" 
            type='agreement' 
          />
        </ProjectInfoBlock>
      </div>
    </fieldset>
  </div>
</template>

<script>

// Load components
import SmallTitle           from '@/components/common/SmallTitle.vue';
import ProjectInfoBlock     from '@/components/project/view/block/ProjectInfoBlock.vue';
import MultipleFileUploader from '@/components/file/MultipleFileUploader.vue';

export default {
  props: {
    project: Object
  },

  /**
   * Componenet data
   */
  data() {
    return {
      projectId: '',
      editRoutePath:  '',
    }
  },

  /**
   * Registration components
   */
  components: {
    MultipleFileUploader,
    ProjectInfoBlock,
    SmallTitle,
  },

  /**
   * Created
   */
  created() {
    this.projectId = this.$route.params.id;
    this.editRoutePath = '/project/edit/agreement/' + this.projectId;
  },
}
</script>

<style scoped>
.row2-form .col:first-child {
  padding-right: 20px;
}
.mt-20 {
  margin-top: 20px;
}
</style>