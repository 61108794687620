<template>
  <div class="project-info-block">
    <div class="title">{{ title }}</div>
    <div class="data">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style scoped>
.project-info-block {
  margin-bottom: 6px;
}
.project-info-block .title {
  margin-bottom: -3px;
  font-weight: 500;
  color: #999;
}
.project-info-block .data {
  font-size: 16px;
  color: #000;
}
</style>