<template>
  <div>
    <fieldset class="form-block">
      <!-- <div class="h-title">
        <h3>
          Winner data
        </h3>
      </div> -->
      <div class="form-container">
        <div v-if="loaded">
          <div v-if="has_auction">
            <SmallTitle 
              title="ข้อมูลผู้ชนะ"
              class="bottom-line"
            />
            <div class="info-2col">
              <div class="info-row">
                <Info2colTitle title="ชื่อบริษัท" />
                <div class="info-detail">{{ member.company_name|nullTo('-') }}</div>
              </div>
              <div class="info-row">
                <Info2colTitle title="ชื่อผูิติดต่อ" />
                <div class="info-detail">{{ member.first_name }} {{ member.last_name }}</div>
              </div>
              <div class="info-row">
                <Info2colTitle title="งบประมาณ" />
                <div class="info-detail">{{ member.budget | formatNumber('.00') }}</div>
              </div>
            </div>
            <a v-if="project.complete==0" href="javascript:void(0)" @click="cancelWinner" class="remove-winner">
              <i class="fa fa-trash"></i> ยกเลิกสถานะผู้ชนะ
            </a>

            <SmallTitle title="ใบเสนอราคา" className="mt-20" />
            <MultipleFileUploader :showUploader="false" type='auction_proposal' :parentId="projectId" :relateId="memberId" section="project" />

            <SmallTitle title="แผนการดำเนินการ" className="mt-20" />
            <MultipleFileUploader :showUploader="false" type='auction_plan' :parentId="projectId" :relateId="memberId" section="project" />
          </div>
          <div v-else class="list-empty">
            <i class="fa fa-ban"></i>
            <span>คุณยังไม่ได้ประมูลโครงการนี้</span>
          </div>
        </div>
        <div v-else>
          <div class="first-loading">
            <i class="fa fa-refresh fa-spin"></i>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import {apiRequest} from '@/utils/axios/axiosInstance.js';

import Info2colTitle from '@/components/common/Info2colTitle.vue'
import SmallTitle from '@/components/common/SmallTitle.vue'
import MultipleFileUploader from '@/components/file/MultipleFileUploader.vue'

export default {
  props: {
    winner_id: {
      type: String,
      default: ''
    },
  },
  components: {
    MultipleFileUploader,
    SmallTitle,
    Info2colTitle,
  },
  data() {
    return {
      loaded: false,
      has_auction: true,

      projectId: '',
      project: {},
      projectStatus: 0,

      memberId: '',
      member: {},
    }
  },
  created() {
    this.loadPage();
  },
  methods: {
    loadPage() {
      if(this.winner_id == '') {
        this.has_auction = false;
        this.loaded = true;
      }else{
        this.projectId = this.$route.params.id;
        this.memberId = this.winner_id;
  
        apiRequest
          .get('/project/'+this.projectId)
          .then( (res) => {
            this.project = res.data.result;
            
            apiRequest
              .get( '/auction/' + this.projectId +'/'+this.memberId)
              .then( (res) => {
                this.member = res.data.result;
                this.loaded = true;
              });
          });
      }
    },
    cancelWinner(){
      let conf = confirm('คุณต้องการยกเลิกผู้ชนะการเสนอราคาใช่หรือไม่ ?');

      if(conf) {
        this.$store.commit('setLoaderShow', true);
        // this.$router.go();
        apiRequest
          .post('/project/cancel-auctioneer', {
            project_id: this.project.PID
          })
          .then( () => {
            this.$router.go();
          });
      }
    },
  }
}
</script>


<style scoped>
.remove-winner {
  display: inline-block;
  /* margin-top: 10px; */
  color: #c00;
}
.first-loading {
  margin: 20px 0;
  text-align: center;
}
.first-loading i {
  font-size: 60px;
  color: #ccc;
}
.list-empty {
  margin: 20px 0;
  padding: 40px 0;
  border-radius: 10px;
  background: #f2f2f2;
  text-align: center;
  font-size: 16px;
  color: #999;
}
.list-empty i {
  display: block;
  margin: 0 0 10px 0;
  font-size: 40px;
  color: #ccc;
}


.col-member-info {
  padding-right: 50px;
  width: 40%;
}
.col-auction-info {
  width: 60%;
}
</style>
 