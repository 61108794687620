<template>
  <button 
    @click="forceCompleteProject()" 
    v-if="
      project.approve == 0 && 
      project.winner_id != 0
    " 
    type="button" 
    class="btn btn-force-complete"
  >
    <i class="fa fa-certificate"></i>
    เลือกผู้ชนะแบบไม่ประมูล
  </button>
</template>


<script>
// import {apiRequest} from '@/utils/axios/axiosInstance.js';

export default {
  props: {
    project: Object
  },

  data() {
    return {
      projectId: ''
    };
  },
  created() {
    this.projectId = this.$route.params.id;
  },
  methods: {
    forceCompleteProject() {
      let conf = confirm("คุณต้องการเปลี่ยนสถานะโครงการนี้เป็น 'ได้ผู้ชนะการประมูลแล้ว' โดยไม่เปิดประมูลใช่หรือไม่ ?");

      if(conf) {
        this.$store.commit('setLoaderShow', true);
        if(conf) {
          this.$router.push('/project/auction-complete/' + this.projectId);
        }
        // let form = {
        //   project_id: this.projectId,
        //   status: 90,
        //   approve: 9,
        //   complete: 9,
        //   kwanjai_code: 'kw-' + this.project.code,
        // };

        // apiRequest
        //   .patch(
        //     '/project/',
        //     form
        //   )
        //   .then((res) => {
        //     this.$store.commit('setLoaderShow', false);
            
        //     // if approve project status is true, refresh this page
        //     // if not alert message
        //     if (res.data.status==200) {
        //       this.$router.go();
        //     } else {
        //       alert('การบันทึกข้อมูลผิดพลาด กรุณาลองใหม่อีกครั้ง')
        //     }
        //   });
      }
    },
  }
}
</script>