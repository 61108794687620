<template>
  <button 
    @click="saveCompleteProject()" 
    v-if="
      project.approve   ==9 && 
      project.winner_id !=0 && 
      project.complete  !=9
    " 
    type="button" 
    class="btn btn-approve"
  >
    <i class="fa fa-approve"></i> 
    ยืนยันผู้ชนะ
  </button>
</template>


<script>
export default {
  props: {
    project: Object
  },

  data() {
    return {
      projectId: ''
    };
  },
  created() {
    this.projectId = this.$route.params.id;
  },
  methods: {
    saveCompleteProject() {
      let conf = confirm("คุณต้องการเปลี่ยนสถานะโครงการนี้เป็น 'ได้ผู้ชนะการประมูลแล้ว' ใช่หรือไม่ ?");

      if(conf) {
        this.$router.push('/project/auction-complete/' + this.projectId);
      }
    },
  }
}
</script>