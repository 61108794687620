<template>
  <div
    class="project--block"
  >
    <SmallTitle
      title="ที่ตั้งและความพร้อม"
      class="bottom-line"
      :editLink="routeEdit"
    />
    
    <ProjectInfoBlock title="ที่อยู่">
      {{ project.address }}
      {{ project.subdistrict }}
      {{ project.district }}
      {{ project.province }}
      {{ project.zipcode }}
    </ProjectInfoBlock>

    <ProjectInfoBlock title="ขนาดพื้นที่">
      {{ project.area }} ตารางเมตร
    </ProjectInfoBlock>
    
    <ProjectInfoBlock title="ขนาดถนนหน้าโครงการ (ถ้ามี)">
      {{ project.road_area | nullTo('-') }}
    </ProjectInfoBlock>

    <ProjectInfoBlock title="ระบุความพร้อมเพื่อใช้ดำเนินงาน">
      {{ project.current_step | currentStepToName }}
    </ProjectInfoBlock>
    
    <ProjectInfoBlock title="สาธารณูปโภคภายในโครงการ">
      <span v-if="project.facility_plumbing == 1">
        ประปา
      </span>
      <span v-if="project.facility_electricity == 1">
        , ไฟฟ้า
      </span>
      <span v-if="project.facility_internet == 1">
        , อินเตอร์เน็ต
      </span>
    </ProjectInfoBlock>

    <ProjectInfoBlock title="มี BOQ หรือไม่ ?">
      <span v-if="project.has_boq == 1">
        ใช่, เรามี BOQ แล้ว
      </span>
      <span v-else>
        -
      </span>
    </ProjectInfoBlock>
    
    <ProjectInfoBlock title="มีแบบบ้าน/โครงการอยู่แล้วหรือไม่ ?">
      <span v-if="project.has_plan == 1">
        ใช่, เรามีแบบบ้าน/โครงการแล้ว
      </span>
      <span v-else>
        -
      </span>
    </ProjectInfoBlock>

    <ProjectInfoBlock title="โครงการหมู่บ้านหรือไม่ ?">
      <span v-if="project.estate == 1">
        ไช่, โครงการของเราเป็นโครงการหมู่บ้าน
      </span>
      <span v-else>
        -
      </span>
    </ProjectInfoBlock>
  </div>
</template>

<script>
// Load library and variables
import {globalVars} from '@/variables/config.js';
import readiness from '@/variables/project/readinessType.js';
// import moment from 'moment';

// Load components
import SmallTitle         from '@/components/common/SmallTitle.vue';
import ProjectInfoBlock   from '@/components/project/view/block/ProjectInfoBlock.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  props: {
    project: Object
  },

  /**
   * All data
   */
  data() {
    return {
      projectId: '',
      previewLink: '',
      routeEdit:  '',
    }
  },

  /**
   * Registration components
   */
  components: {
    SmallTitle,
    ProjectInfoBlock,
  },

  /**
   * Initial this component when created
   */
  mounted() {
    this.projectId = this.$route.params.id;
    this.previewLink = globalVars.url.member + 'project/view/' + this.projectId;
    this.routeEdit = '/project/edit/location/' + this.projectId;
    
   // this.project.job_end = moment(this.project.job_end).format('DD/MM/YYYY');
  },

  /**
   * Filter
   */
  filters: {
    currentStepToName: function (value) {
      return readiness[ value ].name;
    }
  },

  /**
   * Method
   */
  methods: {
    /**
     * Detect current project is approve or not
     */
    isApproved() {
      return this.project.approve == 9;
    }
  }
}
</script>