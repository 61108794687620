<template>
  <div class="duck-form">
    
    <fieldset class="form-block">
      <div class="form-container">
        <SmallTitle 
          title="รายการงวดชำระเงินจากเจ้าของโครงการ"
          class="bottom-line"
        />
        <PaymentPeriod 
          :project="project"
        />
      </div>
    </fieldset>
    <!-- end: Owner Payment -->

    <fieldset class="form-block">
      <div class="form-container">
        <SmallTitle 
          title="รายการงวดจ่ายค่าแรงผู้รับเหมา"
          class="bottom-line"
        />
        <PaymentPeriod 
          mode="constructor"
          :project="project"
        />
      </div>
    </fieldset>
    <!-- end: Wages Payment -->


    <ProjectAgreement
          :project="project"
        />
  </div>
</template>

<script>

// Load components
import SmallTitle   from '@/components/common/SmallTitle.vue';
import PaymentPeriod from '@/components/project/payment/PaymentPeriod.vue';

import ProjectAgreement     from '@/components/project/view/ProjectAgreement.vue';


export default {
  props: {
    project: Object
  },

  /**
   * Registration components
   */
  components: {
    SmallTitle,
    PaymentPeriod,
    ProjectAgreement,
  },
}
</script>

<style scoped>
.row2-form .col:first-child {
  padding-right: 20px;
}
.mt-20 {
  margin-top: 20px;
}
</style>