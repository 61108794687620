<template>
  <button 
    @click="saveApproveAgreement()" 
    v-if="
      project.complete  == 9 &&  
      project.agreement == 0
    " 
    type="button" 
    class="btn btn-approve"
  >
    <i class="fa fa-approve"></i> 
    ยืนยันการเช็นสัญญา
  </button>
</template>


<script>
/**
* ProjectApproveAgreementBtn
*   This is component of ProjectViewTopButton for change project status 
*   from 'waiting_agreement' to 'signed_agreement'. So, this component will display 
*   when project 'complete' value = 9 (auction complete) and 'agreement' is 0 and
*   PaymentList for owner and constructor is completed.
*   
*/

import {apiRequest} from '@/utils/axios/axiosInstance.js';

export default {
  props: {
    project: Object
  },

  data() {
    return {
      projectId: '',
      costRemainOwner: 0,
      costRemainConst: 0,
    };
  },
  created() {
    this.projectId = this.$route.params.id;
  },
  methods: {
    /**
    * Detect from OwnerPaymentList and PaymentToConstructorList is completed
    */
    detectListIsCompleted() {

    },

    /**
     * count remain cost
     */
    countRemainCost(value) {
      let cost_remain = this.project.budget_real;
      if(value.length > 0) {
        value.forEach((item) => cost_remain -= item.cost);
      }
      return cost_remain;
    },

    /**
    * Update data to API
    */
    saveApproveAgreement() {
      let conf = confirm("คุณต้องการเปลี่ยนสถานะโครงการนี้เป็น 'เซ็นสัญญาแล้ว (เริ่มก่อสร้าง)' ใช่หรือไม่ ?");

      // detect comfirmation from admin
      if(conf) {
        let ownerPaymentRemain = this.countRemainCost(this.project.owner_payment);
        let constPaymentRemain = this.countRemainCost(this.project.const_payment);

        // detect PaymentList from 2 list is complete or not
        // if not, alert and cancel process
        if (ownerPaymentRemain != 0 && constPaymentRemain != 0) {
          alert('คุณยังสร้างรายการงวดชำระจากเจ้าของโครงการหรือสร้างสร้างรายการชำระค่าแรงไม่เรียบร้อย !!!');
          return;
        } else {
          this.$store.commit('setLoaderShow', true);
          apiRequest
            .patch(
              '/project/',
              {
                project_id: this.projectId,
                agreement: 9,
              }
            )
            .then((res) => {
              this.$store.commit('setLoaderShow', false);

              // if approve project status is true, refresh this page
              // if not alert message
              if (res.data.status==200) {
                // this.$router.go();
                this.project.agreement = 9;
              } else {
                alert('การบันทึกข้อมูลผิดพลาด กรุณาลองใหม่อีกครั้ง')
              }
            });
        }
      }
    },
  }
}
</script>