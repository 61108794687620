<template>
  <div>
    <fieldset class="form-block">
      <div class="form-container">
        <SmallTitle
          title="ผู้สนใจโครงการ"
          class="bottom-line"
        />

        <!--
          add new Interester
        -->
        <div v-if="haveEditRole()" class="auction-buttun">
          <router-link :to="'/project/find-interest/' + projectId">
            <i class="fa fa-plus"></i> เพิ่มชื่อผู้สนใจ
          </router-link>
        </div>

        <!-- 
          Interester list 
        -->
        <div class="auction-list-container">
          <div v-if="loaded">
            
            <div v-if="auctions.row==0" class="auction-empty">
              <i class="fa fa-list"></i>
              <span>ยังไม่มีผู้สนใจโครงการ</span>
            </div>
            <div v-else class="data-list">
                <table class="tb-data">
                  <colgroup>
                    <col />
                    <col style="width: 120px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>ชื่อผู้สนใจ</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, i) in auctions.result" :key="i">
                      <td>
                        <span v-if="item.business_type==9">
                          <span class="box-ilb"><span>{{ item.company_name }}</span> ({{ item.first_name }} {{ item.last_name }} )</span>
                        </span>
                        <span v-else>{{ item.first_name }} {{ item.last_name }} </span>

                        <ul class="item-action">
                          <li><router-link :to="'/member/edit/'+item.MID" target="_blank" class="edit">ดูข้อมูล</router-link></li>
                          <li v-if="haveEditRole()"><a href="javascript:void(0)" @click="removeInterestItem(item)" class="delete">ลบ</a></li>
                        </ul>
                      </td>
                      <td>
                        <router-link v-if="project.winner_id==0 && haveEditRole()" :to="'/project/auction/'+projectId+'/'+item.MID" class="winner" @click="approveAuction(item)">เสนอราคา</router-link>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <th>ชื่อผู้สนใจ</th>
                      <th>&nbsp;</th>
                    </tr>
                  </tfoot>
                </table>
            </div>
          </div>
          <div v-else>
            <div class="auction-empty">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
  <!-- .col infomation -->
</template>

<script>
import {apiRequest} from '@/utils/axios/axiosInstance.js'

import SmallTitle from '@/components/common/SmallTitle.vue';

export default {
  props: {
    project: Object
  },
  data() {
    return {
      loaded: false,
      projectId: 0,
      auctions: {}
    }
  },
  components: {
    SmallTitle
  },
  created() {
    this.projectId = this.$route.params.id;

    apiRequest
      .get('/auction/'+this.projectId+'/wishlist')
      .then( (res) => {
        this.auctions = res.data;
        this.loaded = true;
      });
  },
  methods: {
    /**
     * have edit data permisstion
     */
    haveEditRole() {
      return this.$store.state.user.type>=50;
    },

    approveAuction(data){
      let name = data.first_name+' '+data.last_name;
      if(data.constructor_type==9) {
        name += '('+data.company_name+')';
      }
      let conf = confirm(name+' ยืนยันการเสนอราคานี้ใช้หรือไม่ ?');

      if(conf) {
        this.$store.commit('setLoaderShow', true);
        
        apiRequest
          .post('/auction/approve', {
            project_id: this.projectId,
            member_id: data.MID
          })
          .then( () => {
            this.$router.go();
          });
      }
    },
    removeInterestItem(data) {
      let name = (data.constructor_type==9) ? data.company_name : data.first_name+' '+data.last_name;
      let conf = confirm('ต้องการลบ '+name+' ออกจากรายชื่อผู้สนใจใช่หรือไม่ ?');

      if(conf) {
        this.$store.commit('setLoaderShow', true);
        
        apiRequest
          .post('/auction/interest-remove', {
            project_id: this.projectId,
            member_id: data.MID
          })
          .then( () => {
            this.$router.go();
          });
      }
    },
  }
}
</script>

<style scoped>
.col-auction .form-block {width: 100%;}
.auction-buttun {margin-bottom: 10px;}
.auction-empty {
  margin: 50px 0;
  text-align: center;
  font-size: 20px;
  color: #999;
}
.auction-empty i {
  display: block;
  margin: 20px 0 10px 0;
  font-size: 60px;
  color: #ccc;
}
.item-action .winner {
  color: #369;
}
</style>