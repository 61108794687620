<template>
  <div
    class="project--block"
  >
    <SmallTitle
      title="งบประมาณและกำหนดการ"
      class="bottom-line"
      :editLink="routeEdit"
    />
    
    <ProjectInfoBlock title="งบประมาณ">
      {{ project.budget }}
    </ProjectInfoBlock>

    <ProjectInfoBlock title="กำหนดการแล้วเสร็จของโครงการ">
      {{ project.job_end }}
    </ProjectInfoBlock>
    
    <ProjectInfoBlock title="กำหนดการเริ่มดำเนินการโดยประมาณ">
      {{ project.timeframe | timeframeToName }}
    </ProjectInfoBlock>
  </div>
</template>

<script>
// Load library and variables
import {globalVars} from '@/variables/config.js';
import timeframe from '@/variables/project/timeframe.js';
// import moment from 'moment';

// Load components
import SmallTitle         from '@/components/common/SmallTitle.vue';
import ProjectInfoBlock   from '@/components/project/view/block/ProjectInfoBlock.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  props: {
    project: Object
  },

  /**
   * All data
   */
  data() {
    return {
      projectId: '',
      previewLink: '',
      routeEdit:  '',
    }
  },

  /**
   * Registration components
   */
  components: {
    SmallTitle,
    ProjectInfoBlock,
  },

  /**
   * Initial this component when created
   */
  mounted() {
    this.projectId = this.$route.params.id;
    this.previewLink = globalVars.url.member + 'project/view/' + this.projectId;
    this.routeEdit = '/project/edit/general/' + this.projectId;
    
    // this.project.job_end = moment(this.project.job_end).format('DD/MM/YYYY');
  },

  /**
   * Filter
   */
  filters: {
    timeframeToName: function (value) {
      return timeframe[ value ].name;
    }
  },

  /**
   * Method
   */
  methods: {
    /**
     * Detect current project is approve or not
     */
    isApproved() {
      return this.project.approve == 9;
    }
  }
}
</script>