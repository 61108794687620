<template>
  <div>
      <SmallTitle
        title="เอกสารประกอบ"
        class="mt-20 bottom-line"
        :editLink="editRoutePath"
      />
      <MultipleFileUploader 
        :parentId="projectId" 
        section="project" 
        type='document' 
        :showUploader="false" 
      />

      <SmallTitle
        title="เอกสาร Survey"
        class="mt-20 bottom-line"
        :editLink="editRoutePath"
      />
      <MultipleFileUploader 
        :parentId="projectId" 
        section="project" 
        type='survey' 
        :showUploader="false" 
      />
  </div>
</template>

<script>

// Load components
import SmallTitle           from '@/components/common/SmallTitle.vue';
import MultipleFileUploader from '@/components/file/MultipleFileUploader.vue';

export default {
  data() {
    return {
      projectId: '',
      editRoutePath:  '',
    }
  },

  /**
   * Registration components
   */
  components: {
    SmallTitle,
    MultipleFileUploader,
  },

  /**
   * Initial this component when created
   */
  created() {
    this.projectId = this.$route.params.id;

    this.editRoutePath = '/project/edit/file/' + this.projectId;
  },
}
</script>