<template>
  <div class="payment--proof">
    <div class="payment--proof--action">
      <a href="#">
        <i class="fa fa-plus"></i>
        เพิ่มข้อมูบการชำระเงิน
      </a>
    </div>
    
  </div>
</template>


<script>
// Load CSS
import '@/assets/css/project/project-form.css';


export default {
  
}
</script>