<template>
  <div class="duck-form">
    <div class="row2 row2-form">
      <div class="col">
        <fieldset class="form-block">
          <div class="form-container">
            <ProjectViewInfoCode :project="project" />
            <ProjectViewInfoGeneral 
              :project="project"
              class="mt-20"
            />
            <ProjectViewInfoBudget 
              :project="project"
              class="mt-20"
            />
            <ProjectViewInfoLocation 
              :project="project"
              class="mt-20"
            />
            <ProjectViewInfoFile />
          </div>
        </fieldset>
        
      </div>
      <!-- main info -->

      <div class="col col-auction">
        <AuctionDetail 
          v-if="project.winner_id!=0"  
          :project="project" 
          :winner_id="project.winner_id"
        />
        <AuctionList 
          :project="project"
        />
        <AuctionWishlist 
          :project="project"
        />
      </div>
    </div>
  </div>
</template>

<script>

// Load components
import ProjectViewInfoCode      from '@/components/project/view/ProjectViewInfoCode.vue'
import ProjectViewInfoGeneral   from '@/components/project/view/ProjectViewInfoGeneral.vue'
import ProjectViewInfoBudget    from '@/components/project/view/ProjectViewInfoBudget.vue'
import ProjectViewInfoLocation  from '@/components/project/view/ProjectViewInfoLocation.vue'
import ProjectViewInfoFile      from '@/components/project/view/ProjectViewInfoFile.vue'

import AuctionDetail        from '@/components/project/AuctionDetail.vue'
import AuctionList          from '@/components/project/AuctionList.vue'
import AuctionWishlist      from '@/components/project/AuctionWishlist.vue'

export default {
  props: {
    project: Object
  },

  /**
   * Registration components
   */
  components: {
    ProjectViewInfoCode,
    ProjectViewInfoGeneral,
    ProjectViewInfoBudget,
    ProjectViewInfoLocation,
    ProjectViewInfoFile,

    AuctionDetail,
    AuctionList,
    AuctionWishlist,
  },
}
</script>

<style scoped>
.row2-form .col:first-child {
  padding-right: 20px;
}
.mt-20 {
  margin-top: 20px;
}
</style>