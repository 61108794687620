<template>
  <div class="view-top-button">
    <!-- v-if="haveEditRole()" -->
    <!-- 
      If project is draft proejct (none approve), 
      system will show approve button.
    -->
    <ProjectOpenAuctionBtn :project="project" />

    <!-- 
      If admin want to manual select Winner without auction.
      Display when current project has 'no approve' status.
    -->
    <ProjectForceCompleteBtn :project="project" />

    <!-- 
      Display when current project has 'approved' status
      and Customer choose Winner.
      
      This button use for confirm Winner to change project status 
      to 'complated'
    -->
    <ProjectAuctionCompleteBtn :project="project" />
    
    <!-- 
      Display when current project has 'complete' status (auction complete)
      but 'agreement' is '0'
    -->
    <ProjectApproveAgreementBtn :project="project" />

    <!-- 
      Display in right top of this page.
      This button use for download all file in project
    -->
    <ProjectDownloadFileBtn />
  </div>
</template>

<script>
import ProjectOpenAuctionBtn      from '@/components/project/form/button/ProjectOpenAuctionBtn.vue'
import ProjectForceCompleteBtn    from '@/components/project/form/button/ProjectForceCompleteBtn.vue'
import ProjectAuctionCompleteBtn  from '@/components/project/form/button/ProjectAuctionCompleteBtn.vue'
import ProjectApproveAgreementBtn from '@/components/project/form/button/ProjectApproveAgreementBtn.vue'
import ProjectDownloadFileBtn     from '@/components/project/form/button/ProjectDownloadFileBtn.vue'

export default {
  props: {
    project: Object
  },

  /**
   * Registration components
   */
  components: {
    ProjectOpenAuctionBtn,
    ProjectForceCompleteBtn,
    ProjectAuctionCompleteBtn,
    ProjectApproveAgreementBtn,
    ProjectDownloadFileBtn,
  },
}
</script>

<style scoped>
.view-top-button {
  margin-bottom: 20px;
}
.btn {
  padding: 5px 15px;
}
/* .btn.btn-approve {
  background: #369;
} */
.btn.btn-approve {
  background: #37A21D;
}
.btn.btn-force-complete {
  background: #150;
}
.btn.btn-download {
  background: #36516C;
}
</style>