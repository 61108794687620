<template>
  <button 
    type="button" 
    @click="clickDownloadZip()" 
    class="btn btn-download"
  >
    <i class="fa fa-download"></i> ดาวน์โหลดไฟล์
  </button>
</template>


<script>
import {apiRequest} from '@/utils/axios/axiosInstance.js';

export default {
  data() {
    return {
      projectId: ''
    };
  },
  created() {
    this.projectId = this.$route.params.id;
  },
  methods: {
    /**
     * Click event handler for download file
     */
    clickDownloadZip() {
      this.$store.commit('setLoaderShow', true);

      let pacames = {
        section: 'project',
        parent_id: this.projectId
      };
      apiRequest
        .get(
          '/file/download_zip', 
          { 
            params: pacames
          }
        )
        .then( (res) => {
          this.$store.commit('setLoaderShow', false);
          
          if(res.data.status==200) {
            window.location.href = res.data.uri;
          } else {
            alert('ไม่พบไฟล์ในเนื้อหานี้');
          }
        })
    },
  }
}
</script>