<template>
  <div class="info-title">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type:String,
      default: ''
    }
  }
}
</script>