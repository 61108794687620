<template>
  <div>
    <fieldset class="form-block">
      <div class="form-container">
        <SmallTitle
          title="ผู้เข้าเสนอราคา"
          class="bottom-line"
        />

        <h5 class="list-stat">
          มีผู้เสนอราคาแล้ว 
          <strong>
            <em :class="{'full': isFullLimit()}">{{ project.count_auctioneer }}</em> / {{ project.auction_limit }}
          </strong> ราย
        </h5>
        <div class="auction-list-container">
          <div v-if="loaded">
            <div v-if="auctions.row==0" class="auction-empty">
              <i class="fa fa-list"></i>
              <span>ยังไม่มีผู้เข้าประมูล</span>
            </div>
            <div v-else class="data-list">
                <table class="tb-data">
                  <colgroup>
                    <col />
                    <col style="width: 120px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>ชื่อผู้เสนอราคา</th>
                      <th>งบประมาณ</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, i) in auctions.result" :key="i">
                      <td :class="{ 'td-approved': item.status==9 }">
                        <span v-if="item.business_type==9">
                          <span class="box-ilb"><span>{{ item.company_name }}</span> ({{ item.first_name }} {{ item.last_name }} )</span>
                        </span>
                        <span v-else>{{ item.first_name }} {{ item.last_name }} </span>
                        <ul v-if="haveEditRole()" class="item-action">
                          <li v-if="project.winner_id==0 && item.status!=0">
                            <a href="javascript:void()" class="winner" @click="chooseWinner(item)">เลือกเป็นผู้ชนะ</a>
                          </li>
                          <li v-if="project.winner_id==0 && item.status==0 && !isFullLimit()">
                            <a href="javascript:void()" class="winner" @click="approveAuction(item)">ยืนยัน</a>
                          </li>
                          <li>
                            <router-link :to="'/project/auction/' + projectId+'/'+item.MID" class="edit">แก้ไข</router-link>
                          </li>
                          <li>
                            <router-link :to="'/member/edit/'+item.MID" target="_blank" class="edit">ดูข้อมูล</router-link>
                          </li>
                          <li>
                            <a @click="removeAuctioneer(item)" class="delete">ลบ</a>
                          </li>
                        </ul>
                        <ul v-else-if="item.status==0">
                          <li>
                            <router-link :to="getViewRoute(item)" class="edit">ตรวจสอบใบเสนอราคา</router-link>
                          </li>
                        </ul>
                      </td>
                      <td>{{ item.budget | formatNumber('.00') }}</td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <th>ชื่อผู้เสนอราคา</th>
                      <th>งบประมาณ</th>
                    </tr>
                  </tfoot>
                </table>
            </div>
          </div>
          <div v-else>
            <div class="auction-empty">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
  <!-- .col infomation -->
</template>

<script>
import {apiRequest} from '@/utils/axios/axiosInstance.js'

import SmallTitle   from '@/components/common/SmallTitle.vue'

export default {
  props: {
    project: Object
  },
  components: {
    SmallTitle
  },
  data() {
    return {
      loaded: false,
      projectId: 0,
      auctions: {}
    }
  },
  created() {
    this.projectId = this.$route.params.id;

    apiRequest
      .get('/auction/'+this.projectId)
      .then( (res) => {
        this.auctions = res.data;
        this.loaded = true;
      });
  },
  methods: {
    /**
     * have edit data permisstion
     */
    haveEditRole() {
      return this.$store.state.user.type>=50;
    },

    /**
     * generate rount path to 'approve auctioneer view'
     */
    getViewRoute(data) {
      return '/project/auction-request/view/'+this.projectId+'/'+data.MID;
    },


    /**
     * choose this auctioneer to winner
     */
    chooseWinner(data){
      let name = data.first_name+' '+data.last_name;
      if(data.constructor_type==9) {
        name += '('+data.company_name+')';
      }
      let conf = confirm(name+' คือคนที่คุณต้องการเลือกเป็นผู้ชนะการเสนอราคาใช่หรือไม่ ?');

      if(conf) {
        this.$store.commit('setLoaderShow', true);
        apiRequest
          .post('/project/choose-auctioneer', {
            project_id: this.projectId,
            winner_id: data.MID
          })
          .then( () => {
            this.$router.go();
          });
      }
    },
    approveAuction(data){
      let name = this.setMemberName(data);
      let conf = confirm(name+' ยืนยันการเสนอราคานี้ใช่หรือไม่ ?');

      if(conf) {
        this.$store.commit('setLoaderShow', true);
        // this.$router.go();
        apiRequest
          .post('/auction/approve', {
            project_id: this.projectId,
            member_id: data.MID
          })
          .then( () => {
            this.$router.go();
          });
      }
    },
    removeAuctioneer(data) {
      let name = this.setMemberName(data);
      let conf = confirm(name+' ลบรายชื่อนี้ออกจากรายชื่อผู้เสนอราคาใช่หรือไม่ ?');
      if(conf) {
        this.$store.commit('setLoaderShow', true);
        apiRequest
          .post('/auction/remove', {
            project_id: this.projectId,
            member_id: data.MID
          })
          .then( () => this.$router.go());
      }
    },
    setMemberName(data) {
      return (data.business_type==9) ? data.company_name : data.first_name+' '+data.last_name
    },

    /**
     * detect auctioneer is full limit
     */
    isFullLimit() {
      return this.project.count_auctioneer >= this.project.auction_limit
    }
  }
}
</script>

<style scoped>
.list-stat {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #666;
}
.list-stat strong {color: #000;}
.list-stat em.full {color: #c00;}
.col-auction .form-block {width: 100%;}
.auction-buttun {margin-bottom: 10px;}
.auction-empty {
  margin: 50px 0;
  text-align: center;
  font-size: 20px;
  color: #999;
}
.auction-empty i {
  display: block;
  margin: 20px 0 10px 0;
  font-size: 60px;
  color: #ccc;
}
.item-action .winner { color: #369; }
.td-approved {
  padding-left: 18px !important;
  border-left: solid 5px #6c0;
}
</style>